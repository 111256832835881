import React from 'react'
import { graphql } from 'gatsby'
import { Container } from 'theme-ui'
import Layout from '@solid-ui-layout/Layout'
import Seo from '@solid-ui-components/Seo'
import Divider from '@solid-ui-components/Divider'
import Header from '@solid-ui-blocks/Header/Block01'
import Hero from '@solid-ui-blocks/Hero/Block01'
import Features from '@solid-ui-blocks/Features/Block05'
import Footer from '@solid-ui-blocks/Footer/Block01'
import TrustNav from '../../../components/TrustNav/TrustNav'
import { normalizeBlockContentNodes } from '@blocks-helpers'
import theme from '../_theme'
import ProductSecurity from '@solid-ui-blocks/Content/Block01'
import OperationalSecurity from '@solid-ui-blocks/Content/Block02'

const IndexPage = props => {
  const { allBlockContent } = props.data
  const content = normalizeBlockContentNodes(allBlockContent?.nodes)
  const style = `.gatsby-image-wrapper { border: none; }`

  return (
    <Layout theme={theme} {...props}>
      <Seo
        title="Enterprise Compliance & Standards - Toggly"
        description="Built on Azure certified infrastructure, Toggly helps maintain compliance with SOC 2, ISO 27001, HIPAA, and other standards while managing feature flags."
        keywords={["compliance", "security standards"]}
        author="opsAI LLC"
        category="Software Development"
        thumbnail="https://toggly.io/cover.png"
        siteUrl="https://toggly.io"
        locale="en_US"
      />

      {/* Blocks */}
      <Header content={content['header-light']} menuJustify='space-between' />

      <Divider space='5' />
      {content['hero'] && <Hero content={content['hero']} reverse />}
      <TrustNav content={content['nav']} />
      <Divider space='4' />


      {content['product-security'] && (
        <>
          <Container>
            <ProductSecurity content={content['product-security']} />
          </Container>
          <Divider space='4' />
        </>
      )}

      {content['operational-security'] && (
        <>
          <Container>
            <OperationalSecurity content={content['operational-security']} />
          </Container>
          <Divider space='4' />
        </>
      )}
      
      <Footer content={content['footer']} />

      <style>{style}</style>
    </Layout>
  )
}

export const query = graphql`
  query homepageSaasBlockContent {
    allBlockContent(filter: { page: { in: ["homepage/trust/compliance", "homepage/trust", "shared"] } }) {
      nodes {
        ...BlockContent
      }
    }
  }
`
export default IndexPage
